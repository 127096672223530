import React from 'react';
import { Container, Col, Row, Form } from 'react-bootstrap';
import Button from '../../components/Button';
import { Filler } from '../../components/Filler';
import { showError, showInfo } from '../../components/Messages';
import Document from '../../components/Document';
import Label from '../../components/Label';
import { numberToString, updateState } from '../../utils/Functions';
import { LayoutParams } from '../../config/LayoutParams';
import '../../contents/css/cabecalho-tabela-formulario-padrao.css';
import '../../contents/css/tabela-formulario-padrao.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';

export default class ImportarEquipamentos extends React.Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   render() {
      let telaDeImportacaoDeEquipamentos = this.props.lang.telaDeImportacaoDeEquipamentos;
      return (
         <div>
            <div
               style={{
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                  color: LayoutParams.colors.corSecundaria,
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  borderLeft: LayoutParams.colors.corDoTemaPrincipal,
               }}
            >
               <div style={{ paddingTop: 0, paddingLeft: 10, paddingRight: 10, height: 45, display: 'flex' }}>
                  <div
                     style={{
                        display: 'table-cell',
                        width: '0',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     <span>{telaDeImportacaoDeEquipamentos.titulo}</span>
                  </div>
               </div>
            </div>
            <Container fluid>
               <Filler height={10} />
               <Row>
                  <Col>
                     <Form.Group>
                        <Label>
                           {telaDeImportacaoDeEquipamentos.planilha + ' (.xlsx)'}&nbsp; &nbsp;&nbsp;
                           <FontAwesomeIcon
                              style={{ fontSize: 20, color: 'green' }}
                              cursor='pointer'
                              icon={faFileExcel}
                              title={telaDeImportacaoDeEquipamentos.baixarPlanilhaModelo}
                              onClick={() => {
                                 let a = document.createElement('a');
                                 a.download = 'MODELO-IMPORTACAO-EQUIPAMENTO';
                                 a.href =
                                    this.props.api.urlBase() +
                                    '/publicfile/download/MODELO-IMPORTACAO-EQUIPAMENTO.xlsx';
                                 document.body.appendChild(a);
                                 a.click();
                                 document.body.removeChild(a);
                              }}
                           />
                        </Label>
                        <Document
                           lang={this.props.lang}
                           api={this.props.api}
                           accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                           defaultValue={this.state.planilha}
                           onChange={(file) => {
                              updateState(this, (state) => {
                                 state.planilha = file;
                              });
                           }}
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <Button
                        text={telaDeImportacaoDeEquipamentos.importar}
                        inProgressText={telaDeImportacaoDeEquipamentos.importando}
                        style={{ width: 160 }}
                        onClickAsync={() => {
                           return new Promise((resolve, reject) => {
                              if (!this.state.planilha) {
                                 showError(telaDeImportacaoDeEquipamentos.mensagens.selecioneUmArquivo);
                                 reject();
                                 return;
                              }
                              this.props.api
                                 .post('/equipamento/importarplanilha', this.state.planilha)
                                 .then((result) => {
                                    if (result.temErro) {
                                       showInfo(telaDeImportacaoDeEquipamentos.mensagens.ocorreramErrosNaImportacao);
                                       this.setState({ retorno: result });                                       
                                    } else {
                                       showInfo(telaDeImportacaoDeEquipamentos.mensagens.dadosProcessadosComSucesso);
                                       this.setState({ retorno: result });                                       
                                    }
                                    resolve();
                                 })
                                 .catch(reject);
                           });
                        }}
                     />
                  </Col>
               </Row>

               {this.state.retorno && this.state.planilha && (
                  <div>
                     <br />
                     <div>
                        <span>{telaDeImportacaoDeEquipamentos.linhas}:&nbsp;</span>
                        <span>{numberToString(this.state.retorno.total, 0)}</span>
                        <br />
                        <span>{telaDeImportacaoDeEquipamentos.processadosComSucesso}:&nbsp;</span>
                        <span>{numberToString(this.state.retorno.importados, 0)}</span>
                        <br />
                        <span>{telaDeImportacaoDeEquipamentos.erros}:&nbsp;</span>
                        <span>{numberToString(this.state.retorno.naoImportados, 0)}</span>
                     </div>

                     <br />

                     {this.state.retorno.itens && this.state.retorno.itens.length > 0 && (
                        <table className='tabela-formulario-padrao'>
                           <thead style={{ width: '100%' }}>
                              <tr>
                                 <th style={{ width: '5%' }}>{telaDeImportacaoDeEquipamentos.linha}</th>
                                 <th style={{ width: '30%' }}>{telaDeImportacaoDeEquipamentos.nomeDoEquipamento}</th>
                                 <th style={{ width: '15%' }}>{telaDeImportacaoDeEquipamentos.numeroDeSerie} </th>
                                 <th style={{ width: '15%' }}>{telaDeImportacaoDeEquipamentos.patrimonio} </th>
                                 <th style={{ width: '10%' }}>{telaDeImportacaoDeEquipamentos.macDaTag} </th>
                                 <th style={{ width: '25%' }}>{telaDeImportacaoDeEquipamentos.erro}</th>
                              </tr>
                           </thead>
                           <tbody style={{ width: '100%' }}>
                              {this.state.retorno.itens.map((item, index) => {
                                 return (
                                    <tr key={index}>
                                       <td style={{ width: '5%' }}>{item.linha}</td>
                                       <td style={{ width: '30%' }}>{item.nome}</td>
                                       <td style={{ width: '15%' }}>{item.numeroDeSerie}</td>
                                       <td style={{ width: '15%' }}>{item.patrimonio}</td>
                                       <td style={{ width: '10%' }}>{item.macDaTag}</td>
                                       <td style={{ width: '25%' }}>{item.mensagem}</td>
                                    </tr>
                                 );
                              })}
                           </tbody>
                        </table>
                     )}

                     <br />
                  </div>
               )}
            </Container>
         </div>
      );
   }
}
