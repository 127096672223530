import { showError } from '../../components/Messages';
import { toEntityReference, updateState } from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class GatewayController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'GatewayController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeGateway.mac, width: '20%', orderby: 'mac' },
         { titulo: this.lang.telaDeCadastroDeGateway.patrimonio, width: '15%', orderby: 'patrimonio' },
         { titulo: this.lang.telaDeCadastroDeGateway.predio, width: '20%', orderby: null},
         { titulo: this.lang.telaDeCadastroDeGateway.andar, width: '10%', orderby: null},
         { titulo: this.lang.telaDeCadastroDeGateway.local, width: '20%', orderby: null },
         { titulo: this.lang.telaDeCadastroDeGateway.situacao, width: '15%', orderby: 'situacao' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.mac,
         item.patrimonio,
         item.nomeDoPredio,
         item.andar,
         item.nomeDoLocal,
         item.situacao ? item.situacao.descricao : null,
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.mac) {
            showError(this.lang.telaDeCadastroDeGateway.mensagens.informeOMacDoGateway);
            reject();
            return;
         }

         var input = {
            mac: item.mac,
            patrimonio: item.patrimonio,
            situacao: item.situacao ? item.situacao.id : null,
            cadastrarTagsProximas: item.cadastrarTagsProximas,
            indicadorDeTagsEmManutencao: item.indicadorDeTagsEmManutencao,
            distanciaMaxima: item.distanciaMaxima,
            notificacoesDeGatewayOffline: item.notificacoesDeGatewayOffline,
            local: toEntityReference(item.local),
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };

   antesDeEditar = () => {
      return updateState(this.view, (state) => {
         state.dataDeFiltroDeLog = new Date();
         state.filtroDeLog = null;
         state.gateway = null;
         state.formPacotes = {};
         state.formLogs = {};
      });
   };
}
