import React from 'react';
import { FormControl } from 'react-bootstrap';
import '../contents/css/text-input.css';

export default class TextInput extends React.Component {
   render() {
      return (
         <FormControl
            className={'text-input' + (this.props.className ? ' ' + this.props.className : '')}
            style={this.props.style}
            name={this.props.name}
            id={this.props.id}
            key={this.props.key}
            ref={this.props.ref}
            maxLength={this.props.maxLength}
            onChange={(e) => {
               let oldSelectionStart = e.target.selectionStart;
               let oldSelectionEnd = e.target.selectionEnd;

               if (this.props.upperCase) {
                  e.target.value = ('' + e.target.value).toUpperCase();
               }
               if (this.props.lowerCase) {
                  e.target.value = ('' + e.target.value).toLowerCase();
               }

               e.target.selectionStart = oldSelectionStart;
               e.target.selectionEnd = oldSelectionEnd;

               if (this.props.onChange) {
                  this.props.onChange(e);
               }
            }}
            defaultValue={this.props.defaultValue}
            placeholder={this.props.placeholder}
            readOnly={this.props.readOnly}
         />
      );
   }
}
