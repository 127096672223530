import { BaseController } from './../BaseController';
import Api from '../../utils/Api';

export default class QuantidadeDeAcessoAoSistemaController extends BaseController {
    constructor(props) {
        super(props);
        this.api = new Api();
        this.name = 'QuantidadeDeAcessoAoSistemaController';
    }

    obterQuantidadeDeAcessoAoSistemaPorEmpresa(){
        return new Promise((resolve, reject) => {
            this.api
            .getAll(
               '/quantidadedeacessoaosistema' 
            )
            .then((result) => {               
               this.view.setState({ itens: result }, () => {
                  resolve();
               });
            }).catch((reason)=> reject(reason));   
        });
                 
    }


}