import { FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import ParametroDoSistemaController from '../../controllers/Tecnometrics/ParametroDoSistemaController';
import Select from '../../components/Select';
import PasswordInput from '../../components/PasswordInput';
import { updateState } from '../../utils/Functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default class ParametroDoSistemaView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new ParametroDoSistemaController(this);
      this.comboParametros = this.controller.comboParametros();
   }

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeParametrosDoSistema.titulo}
            url={'/parametrodosistema'}
            api={this.props.api}
            protected={true}
            lang={this.props.lang}
            ordenacaoPadrao={'grupo, ordem'}
            permissoes={[1041, 1042, 1043, 1044]}
            antesDeEditar={this.antesDeEditar}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            select={this.props.select}
            itemVazio={{}}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            getFormState={() => {
               return this.state;
            }}
         />
      );
   }

   antesDeEditar = (sender, item) => {
      return new Promise((resolve) => {
         this.setState({ tipoDeControle: item.protegido ? 'password' : 'text' }, resolve);
      });
   };

   renderizarFormulario = () => {
      let parametro =
         this.state.itemSelecionado && this.state.itemSelecionado.nome
            ? this.comboParametros.filter((i) => i.nome === this.state.itemSelecionado.nome)[0]
            : null;
      let lang = this.props.lang;
      let tela = lang.telaDeCadastroDeParametrosDoSistema;
      return (
         <React.Fragment>
            {/* <FormGroup>
                    <Label>{lang.codigo}</Label>
                    <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                </FormGroup> */}
            <FormGroup>
               <Label>{tela.parametro}</Label>
               <Select
                  options={this.comboParametros}
                  name='parametro'
                  defaultValue={this.state.itemSelecionado ? this.state.itemSelecionado.nome : ''}
                  onSelect={(i) => {
                     if (i) {
                        updateState(this, (state) => {
                           state.itemSelecionado.nome = i.nome;
                           state.itemSelecionado.descricao = i.descricao;
                           state.itemSelecionado.protegido = i.protegido;
                           state.itemSelecionado.grupo = i.grupo;
                           state.itemSelecionado.ordem = i.ordem;
                           state.itemSelecionado.componente = i.componente;
                        });
                     } else {
                        updateState(this, (state) => {
                           state.itemSelecionado.nome = null;
                           state.itemSelecionado.descricao = null;
                           state.itemSelecionado.protegido = null;
                           state.itemSelecionado.grupo = null;
                           state.itemSelecionado.ordem = null;
                           state.itemSelecionado.componente = null;
                        });
                     }
                     this.setState({
                        tipoDeControle: i && i.protegido ? 'password' : 'text',
                     });
                     this.setState({ itemSelecionado: this.state.itemSelecionado });
                  }}
                  getKeyValue={(i) => i.nome}
                  getDescription={(i) => i.descricao}
               />
            </FormGroup>

            {parametro && !parametro.componente && this.state.tipoDeControle !== 'password' && (
               <FormGroup>
                  <Label>{parametro.descricao}</Label>
                  <TextInput
                     type='text'
                     defaultValue={this.state.itemSelecionado.valor}
                     onChange={(e) => {
                        updateState(this, (state) => (state.itemSelecionado.valor = e.target.value));
                     }}
                  />
               </FormGroup>
            )}

            {parametro && !parametro.componente && this.state.tipoDeControle === 'password' && (
               <FormGroup>
                  <Label>{parametro.descricao}</Label>
                  <PasswordInput
                     defaultValue={this.state.itemSelecionado.valor}
                     onChange={(e) => {
                        updateState(this, (state) => (state.itemSelecionado.valor = e.target.value));
                     }}
                     placeholder={this.state.itemSelecionado.preenchido ? tela.sobrescreverOValorAtual : null}
                  />
               </FormGroup>
            )}

            {parametro && parametro.componente && (
               <div>
                  {parametro.componente(this.state.itemSelecionado.valor, (valor) => {
                     updateState(this, (state) => (state.itemSelecionado.valor = valor));
                  })}
               </div>
            )}

            {parametro && parametro.ajuda && (
               <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <div style={{ display: 'table-cell', fontSize: 22, color: '#0062cc' }}>
                     <FontAwesomeIcon icon={faInfoCircle} />
                  </div>
                  <div style={{ display: 'table-cell', fontSize: 14, paddingTop: 5, paddingLeft: 3 }}>
                     <span style={{ whiteSpace: 'pre-wrap' }}>{parametro.ajuda}</span>
                  </div>
               </div>
            )}
         </React.Fragment>
      );
   };
}
