import { Row, Col, FormGroup, Modal } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import DecimalInput from '../../components/DecimalInput';
import EquipamentoController from '../../controllers/Empresa/EquipamentoController';
import { buildQueryString, isNumeric, prepareSearchable, updateState } from '../../utils/Functions';
import Select from '../../components/Select';
import SubCadastro from '../../components/SubCadastro';
import TagView from './TagView';
import HistoricoDeTemperaturaView from './HistoricoDeTemperaturaView';
import HistoricoDeUmidadeView from './HistoricoDeUmidadeView';
import LocalView from './LocalView';
import ServicoDeNotificacaoView from './ServicoDeNotificacaoView';
import { Redirect } from 'react-router-dom';
import { showError } from '../../components/Messages';
import Line from '../../components/Line';

export default class EquipamentoView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         mostrarAcessos: true,
         abaSelecionada: 'pacotes',
         filtroDeSituacao: 1,
      };
      this.controller = new EquipamentoController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let tela = lang.telaDeCadastroDeEquipamento;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2} xl={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.numeroDeSerie}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.numeroDeSerie}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.numeroDeSerie = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.patrimonio}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.patrimonio}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.patrimonio = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.situacao}</Label>
                     <Select
                        as='select'
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.situacaoDeEquipamento.ativo },
                           { id: 2, descricao: lang.situacaoDeEquipamento.inativo },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col sm={12} md={12} lg={4} xl={4}>
                  <Label>{tela.tag}</Label>
                  <Select
                     name={'tag'}
                     defaultValue={this.state.itemSelecionado.tag}
                     getKeyValue={(i) => i.id}
                     getDescription={(i) => i.mac}
                     onSelect={(i) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.tag = i;
                        });
                     }}
                     formularioPadrao={(select) => {
                        return <TagView api={this.props.api} select={select} lang={this.props.lang} />;
                     }}
                     noDropDown={true}
                     readOnlyColor={'#ffff'}
                     getFilterUrl={(text) =>
                        '/tag' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                     }
                  />
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.local}</Label>
                     <Select
                        name={'localAtual'}
                        defaultValue={this.state.itemSelecionado.local}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.local = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <LocalView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor={'#ffff'}
                        getFilterUrl={(text) =>
                           '/local' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                        }
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.medicaoMinimaDeTemperatura}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.medicaoMinimaDeTemperatura}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.medicaoMinimaDeTemperatura = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.medicaoMaximaDeTemperatura}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.medicaoMaximaDeTemperatura}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.medicaoMaximaDeTemperatura = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.limiteInferiorDeTemperatura}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.limiteInferiorDeTemperatura}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.limiteInferiorDeTemperatura = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.limiteSuperiorDeTemperatura}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.limiteSuperiorDeTemperatura}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.limiteSuperiorDeTemperatura = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.toleranciaParaNotificacao}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.toleranciaParaNotificacaoDeTemperatura}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.toleranciaParaNotificacaoDeTemperatura = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.medicaoMinimaDeUmidade}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.medicaoMinimaDeUmidade}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.medicaoMinimaDeUmidade = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.medicaoMaximaDeUmidade}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.medicaoMaximaDeUmidade}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.medicaoMaximaDeUmidade = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.toleranciaParaNotificacao}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.toleranciaParaNotificacaoDeUmidade}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.toleranciaParaNotificacaoDeUmidade = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.limiteInferiorDeUmidade}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.limiteInferiorDeUmidade}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.limiteInferiorDeUmidade = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.limiteSuperiorDeUmidade}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.limiteSuperiorDeUmidade}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.limiteSuperiorDeUmidade = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={tela.notificacoesDeBateriaFraca}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.notificacoesDeBateriaFraca}
               lang={lang}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.notificacoesDeBateriaFraca.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: '', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.servicoDeNotificacao.nome];
               }}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.notificacaoDeBateriaFracaSelecionada = {};
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        if (!state.itemSelecionado.notificacoesDeBateriaFraca) {
                           state.itemSelecionado.notificacoesDeBateriaFraca = [];
                        }
                        state.itemSelecionado.notificacoesDeBateriaFraca.push({
                           servicoDeNotificacao: this.state.notificacaoDeBateriaFracaSelecionada.servicoDeNotificacao,
                        });
                     }).then(() => resolve());
                  });
               }}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.notificacaoDeBateriaFracaSelecionada = JSON.parse(
                           JSON.stringify(state.itemSelecionado.notificacoesDeBateriaFraca[index])
                        );
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.notificacoesDeBateriaFraca[index].servicoDeNotificacao =
                           this.state.notificacaoDeBateriaFracaSelecionada.servicoDeNotificacao;
                     }).then(() => resolve());
                  });
               }}
               formulario={() => {
                  return (
                     <Fragment>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{tela.servicoDeNotificacao}</Label>
                                 <Select
                                    name={'servicoDeNotificacao'}
                                    defaultValue={this.state.notificacaoDeBateriaFracaSelecionada.servicoDeNotificacao}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.nome}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          state.notificacaoDeBateriaFracaSelecionada.servicoDeNotificacao = i;
                                       });
                                    }}
                                    formularioPadrao={(select) => {
                                       return (
                                          <ServicoDeNotificacaoView
                                             api={this.props.api}
                                             select={select}
                                             lang={this.props.lang}
                                          />
                                       );
                                    }}
                                    noDropDown={true}
                                    readOnlyColor={'#ffff'}
                                    getFilterUrl={(text) =>
                                       '/servicodenotificacao' +
                                       buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                     </Fragment>
                  );
               }}
            />

            <br />

            <SubCadastro
               titulo={tela.notificacoesDeMudancaDeTemperatura}
               exibirTitulos={true}
               itens={this.state.itemSelecionado.notificacoesDeMudancaDeTemperatura}
               lang={lang}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.notificacoesDeMudancaDeTemperatura.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [
                     { titulo: tela.nome, width: '40%' },
                     { titulo: tela.limiteInferior, width: '20%' },
                     { titulo: tela.limiteSuperior, width: '20%' },
                     { titulo: tela.permaneciaMinima, width: '20%' },
                  ];
               }}
               linha={(item) => {
                  return [
                     item.servicoDeNotificacao.nome,
                     item.limiteInferior,
                     item.limiteSuperior,
                     item.permaneciaMinima,
                  ];
               }}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.notificacaoDeMudancaDeTemperaturaSelecionada = {};
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        if (!state.itemSelecionado.notificacoesDeMudancaDeTemperatura) {
                           state.itemSelecionado.notificacoesDeMudancaDeTemperatura = [];
                        }
                        state.itemSelecionado.notificacoesDeMudancaDeTemperatura.push({
                           servicoDeNotificacao:
                              this.state.notificacaoDeMudancaDeTemperaturaSelecionada.servicoDeNotificacao,
                           limiteInferior: this.state.notificacaoDeMudancaDeTemperaturaSelecionada.limiteInferior,
                           limiteSuperior: this.state.notificacaoDeMudancaDeTemperaturaSelecionada.limiteSuperior,
                           permaneciaMinima: this.state.notificacaoDeMudancaDeTemperaturaSelecionada.permaneciaMinima,
                        });
                     }).then(() => resolve());
                  });
               }}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.notificacaoDeMudancaDeTemperaturaSelecionada = JSON.parse(
                           JSON.stringify(state.itemSelecionado.notificacoesDeMudancaDeTemperatura[index])
                        );
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.notificacoesDeMudancaDeTemperatura[index].servicoDeNotificacao =
                           this.state.notificacaoDeMudancaDeTemperaturaSelecionada.servicoDeNotificacao;
                        state.itemSelecionado.notificacoesDeMudancaDeTemperatura[index].limiteInferior =
                           this.state.notificacaoDeMudancaDeTemperaturaSelecionada.limiteInferior;
                        state.itemSelecionado.notificacoesDeMudancaDeTemperatura[index].limiteSuperior =
                           this.state.notificacaoDeMudancaDeTemperaturaSelecionada.limiteSuperior;
                        state.itemSelecionado.notificacoesDeMudancaDeTemperatura[index].permaneciaMinima =
                           this.state.notificacaoDeMudancaDeTemperaturaSelecionada.permaneciaMinima;
                     }).then(() => resolve());
                  });
               }}
               formulario={() => {
                  return (
                     <Fragment>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{tela.servicoDeNotificacao}</Label>
                                 <Select
                                    name={'servicoDeNotificacao'}
                                    defaultValue={
                                       this.state.notificacaoDeMudancaDeTemperaturaSelecionada.servicoDeNotificacao
                                    }
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.nome}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          state.notificacaoDeMudancaDeTemperaturaSelecionada.servicoDeNotificacao = i;
                                       });
                                    }}
                                    formularioPadrao={(select) => {
                                       return (
                                          <ServicoDeNotificacaoView
                                             api={this.props.api}
                                             select={select}
                                             lang={this.props.lang}
                                          />
                                       );
                                    }}
                                    noDropDown={true}
                                    readOnlyColor={'#ffff'}
                                    getFilterUrl={(text) =>
                                       '/servicodenotificacao' +
                                       buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row>
                           <Col>
                              <span>{tela.regrasParaNotificar}</span>
                           </Col>
                        </Row>

                        <Line marginTop={5} />

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 6 }}>
                           <div style={{ width: '100%', paddingTop: 10 }}>
                              <span>{tela.seATemperaturaForInferiorA}</span>
                           </div>
                           <div>
                              <DecimalInput
                                 defaultValue={this.state.notificacaoDeMudancaDeTemperaturaSelecionada.limiteInferior}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.notificacaoDeMudancaDeTemperaturaSelecionada.limiteInferior = e.floatValue;
                                    });
                                 }}
                                 decimalPlaces={0}
                              />
                           </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 6 }}>
                           <div style={{ width: '100%', paddingTop: 10 }}>
                              <span>{tela.ouSeTemperaturaForSuperiorA}</span>
                           </div>
                           <div>
                              <DecimalInput
                                 defaultValue={this.state.notificacaoDeMudancaDeTemperaturaSelecionada.limiteSuperior}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.notificacaoDeMudancaDeTemperaturaSelecionada.limiteSuperior = e.floatValue;
                                    });
                                 }}
                                 decimalPlaces={0}
                              />
                           </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 6 }}>
                           <div style={{ width: '100%', paddingTop: 10 }}>
                              <span>{tela.permanenciaMinimaEmMinutosForaDosParametrosParaNotificar}</span>
                           </div>
                           <div>
                              <IntegerInput
                                 defaultValue={this.state.notificacaoDeMudancaDeTemperaturaSelecionada.permaneciaMinima}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.notificacaoDeMudancaDeTemperaturaSelecionada.permaneciaMinima =
                                          e.floatValue;
                                    });
                                 }}
                              />
                           </div>
                        </div>
                     </Fragment>
                  );
               }}
            />

            <br />

            <SubCadastro
               titulo={tela.notificacoesDeMudancaDeUmidade}
               exibirTitulos={true}
               itens={this.state.itemSelecionado.notificacoesDeMudancaDeUmidade}
               lang={lang}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.notificacoesDeMudancaDeUmidade.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [
                     { titulo: tela.nome, width: '40%' },
                     { titulo: tela.limiteInferior, width: '20%' },
                     { titulo: tela.limiteSuperior, width: '20%' },
                     { titulo: tela.permaneciaMinima, width: '20%' },
                  ];
               }}
               linha={(item) => {
                  return [
                     item.servicoDeNotificacao.nome,
                     item.limiteInferior,
                     item.limiteSuperior,
                     item.permaneciaMinima,
                  ];
               }}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.notificacaoDeMudancaDeUmidadeSelecionada = {};
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        if (!state.itemSelecionado.notificacoesDeMudancaDeUmidade) {
                           state.itemSelecionado.notificacoesDeMudancaDeUmidade = [];
                        }
                        state.itemSelecionado.notificacoesDeMudancaDeUmidade.push({
                           servicoDeNotificacao:
                              this.state.notificacaoDeMudancaDeUmidadeSelecionada.servicoDeNotificacao,
                           limiteInferior: this.state.notificacaoDeMudancaDeUmidadeSelecionada.limiteInferior,
                           limiteSuperior: this.state.notificacaoDeMudancaDeUmidadeSelecionada.limiteSuperior,
                           permaneciaMinima: this.state.notificacaoDeMudancaDeUmidadeSelecionada.permaneciaMinima,
                        });
                     }).then(() => resolve());
                  });
               }}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.notificacaoDeMudancaDeUmidadeSelecionada = JSON.parse(
                           JSON.stringify(state.itemSelecionado.notificacoesDeMudancaDeUmidade[index])
                        );
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.notificacoesDeMudancaDeUmidade[index].servicoDeNotificacao =
                           this.state.notificacaoDeMudancaDeUmidadeSelecionada.servicoDeNotificacao;
                        state.itemSelecionado.notificacoesDeMudancaDeUmidade[index].limiteInferior =
                           this.state.notificacaoDeMudancaDeUmidadeSelecionada.limiteInferior;
                        state.itemSelecionado.notificacoesDeMudancaDeUmidade[index].limiteSuperior =
                           this.state.notificacaoDeMudancaDeUmidadeSelecionada.limiteSuperior;
                        state.itemSelecionado.notificacoesDeMudancaDeUmidade[index].permaneciaMinima =
                           this.state.notificacaoDeMudancaDeUmidadeSelecionada.permaneciaMinima;
                     }).then(() => resolve());
                  });
               }}
               formulario={() => {
                  return (
                     <Fragment>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{tela.servicoDeNotificacao}</Label>
                                 <Select
                                    name={'servicoDeNotificacao'}
                                    defaultValue={
                                       this.state.notificacaoDeMudancaDeUmidadeSelecionada.servicoDeNotificacao
                                    }
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.nome}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          state.notificacaoDeMudancaDeUmidadeSelecionada.servicoDeNotificacao = i;
                                       });
                                    }}
                                    formularioPadrao={(select) => {
                                       return (
                                          <ServicoDeNotificacaoView
                                             api={this.props.api}
                                             select={select}
                                             lang={this.props.lang}
                                          />
                                       );
                                    }}
                                    noDropDown={true}
                                    readOnlyColor={'#ffff'}
                                    getFilterUrl={(text) =>
                                       '/servicodenotificacao' +
                                       buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row>
                           <Col>
                              <span>{tela.regrasParaNotificar}</span>
                           </Col>
                        </Row>

                        <Line marginTop={5} />

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 6 }}>
                           <div style={{ width: '100%', paddingTop: 10 }}>
                              <span>{tela.seAUmidadeForInferiorA}</span>
                           </div>
                           <div>
                              <DecimalInput
                                 defaultValue={this.state.notificacaoDeMudancaDeUmidadeSelecionada.limiteInferior}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.notificacaoDeMudancaDeUmidadeSelecionada.limiteInferior = e.floatValue;
                                    });
                                 }}
                                 decimalPlaces={0}
                              />
                           </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 6 }}>
                           <div style={{ width: '100%', paddingTop: 10 }}>
                              <span>{tela.ouSeUmidadeForSuperiorA}</span>
                           </div>
                           <div>
                              <DecimalInput
                                 defaultValue={this.state.notificacaoDeMudancaDeUmidadeSelecionada.limiteSuperior}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.notificacaoDeMudancaDeUmidadeSelecionada.limiteSuperior = e.floatValue;
                                    });
                                 }}
                                 decimalPlaces={0}
                              />
                           </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 6 }}>
                           <div style={{ width: '100%', paddingTop: 10 }}>
                              <span>{tela.permanenciaMinimaEmMinutosForaDosParametrosParaNotificar}</span>
                           </div>
                           <div>
                              <IntegerInput
                                 defaultValue={this.state.notificacaoDeMudancaDeUmidadeSelecionada.permaneciaMinima}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.notificacaoDeMudancaDeUmidadeSelecionada.permaneciaMinima = e.floatValue;
                                    });
                                 }}
                              />
                           </div>
                        </div>
                     </Fragment>
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   modalHistoricoTemperatura = () => {
      return (
         this.state.mostrarHistoricoDeTemperatura && (
            <Modal
               show={this.state.mostrarHistoricoDeTemperatura}
               scrollable={true}
               size={'lg'}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.setState({ mostrarHistoricoDeTemperatura: false });
               }}
               dialogClassName='h-100'
            >
               <Modal.Body
                  style={{
                     overflow: 'hidden',
                     display: 'flex',
                     position: 'relative',
                     fontSize: 13,
                     padding: '0 0 0 0',
                     maxHeight: '100%',
                  }}
               >
                  <HistoricoDeTemperaturaView
                     lang={this.props.lang}
                     api={this.props.api}
                     idDoEquipamento={this.state.id}
                     titulo={this.state.nome}
                     aoFechar={() => {
                        this.setState({ mostrarHistoricoDeTemperatura: false });
                     }}
                  />
               </Modal.Body>
            </Modal>
         )
      );
   };

   modalHistoricoUmidade = () => {
      return (
         this.state.mostrarHistoricoDeUmidade && (
            <Modal
               show={this.state.mostrarHistoricoDeUmidade}
               scrollable={true}
               size={'lg'}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.setState({ mostrarHistoricoDeUmidade: false });
               }}
               dialogClassName='h-100'
            >
               <Modal.Body
                  style={{
                     overflow: 'hidden',
                     display: 'flex',
                     position: 'relative',
                     fontSize: 13,
                     padding: '0 0 0 0',
                     maxHeight: '100%',
                  }}
               >
                  <HistoricoDeUmidadeView
                     lang={this.props.lang}
                     api={this.props.api}
                     idDoEquipamento={this.state.id}
                     titulo={this.state.nome}
                     aoFechar={() => {
                        this.setState({ mostrarHistoricoDeUmidade: false });
                     }}
                  />
               </Modal.Body>
            </Modal>
         )
      );
   };

   getFiltro = (filtro) => {
      // Pode ser sobrescrito caso seja necessário ter mais algum filtro na tela.
      var result = this.props.filtroExtra ? this.props.filtroExtra() : {};
      if (filtro && filtro.texto) {
         let texto = filtro.texto.toString();
         if (texto[0] === '#') {
            if (!isNumeric(texto.substring(1))) {
               showError(this.props.lang.formularioPadrao.codigoInvalido + ': "' + texto.substring(1) + '"');
               return result;
            }
            result.id = texto.substring(1);
         } else {
            result.searchable = prepareSearchable(texto);
         }
      }

      result.situacao = this.state.filtroDeSituacao;

      return result;
   };

   render() {
      return (
         <Fragment>
            {this.modalHistoricoTemperatura()}
            {this.modalHistoricoUmidade()}
            <FormularioPadrao
               titulo={this.props.lang.telaDeCadastroDeEquipamento.titulo}
               url={'/equipamento'}
               fastUrl={'/equipamento/fast'}
               api={this.props.api}
               lang={this.props.lang}
               ref={(c) => (this.form = c)}
               ordenacaoPadrao={'nome'}
               permissoes={[1431, 1432, 1433, 1434]}
               getFiltro={this.getFiltro}
               filtroExtra={this.props.filtroExtra}
               getTitulosDaTabela={this.controller.getTitulosDaTabela}
               getDadosDaTabela={this.controller.getDadosDaTabela}
               renderizarFormulario={this.renderizarFormulario}
               getObjetoDeDados={this.controller.getObjetoDeDados}
               antesDeInserir={this.controller.antesDeInserir}
               antesDeEditar={this.controller.antesDeEditar}
               antesDeSalvar={this.controller.antesDeSalvar}
               antesDeExcluir={this.controller.antesDeExcluir}
               getFormState={() => {
                  return this.state;
               }}
               setFormState={(state, callback) => {
                  return new Promise((resolve) => {
                     this.setState(state, () => {
                        resolve();
                        if (callback) {
                           callback();
                        }
                     });
                  });
               }}
               select={this.props.select}
               itemVazio={{
                  situacao: { id: 1 },
               }}
               menus={[
                  {
                     titulo: this.props.lang.telaDeCadastroDeEquipamento.importar,
                     acao: () => this.setState({ irParaImportacao: true }),
                  },
                  {
                     titulo: this.props.lang.telaDeCadastroDeEquipamento.situacao,
                     subMenus: [
                        {
                           conteudo: this.props.lang.formularioPadrao.ativos,
                           acao: () =>
                              updateState(this, (state) => {
                                 state.filtroDeSituacao = 1;
                              }).then(this.form.filtrar()),
                        },
                        {
                           conteudo: this.props.lang.formularioPadrao.inativos,
                           acao: () =>
                              updateState(this, (state) => {
                                 state.filtroDeSituacao = 2;
                              }).then(this.form.filtrar()),
                        },
                        {
                           conteudo: this.props.lang.formularioPadrao.todos,
                           acao: () =>
                              updateState(this, (state) => {
                                 state.filtroDeSituacao = null;
                              }).then(this.form.filtrar()),
                        },
                     ],
                  },
               ]}
            />
            {this.state.irParaImportacao && <Redirect to='/importarequipamentos' />}
         </Fragment>
      );
   }
}
