import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { LayoutParams } from '../config/LayoutParams';
import '../contents/css/button-input.css';

export default class DropdownButton extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         text: this.props.text,
         disabled: false,
      };
   }

   focus = () => {
      if (this.button) {
         this.button.focus();
      }
   };

   render() {
      let defaultStyle = {
         backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
         borderBlockColor: LayoutParams.colors.corSecundaria,
         color: LayoutParams.colors.corSecundaria,
         borderColor: LayoutParams.colors.corSecundaria,
         fontSize: 16,
         height: 36,
         textAlign: 'center',
         margin: 0,
         padding: '0 4px 0 4px',
         overFlow: 'hidden',
      };

      return (
         <Dropdown>
            <Dropdown.Toggle
               className={'button-input' + (this.props.className ? ' ' + this.props.className : '')}
               style={{ ...defaultStyle, ...this.props.style }}
               title={this.props.title}
               name={this.props.name}
               id={this.props.id}
               key={this.props.key}
               ref={(c) => {
                  this.button = c;
               }}
               disabled={this.state.disabled || this.props.disabled}
               block
               type={this.props.submit ? 'submit' : 'button'}
            >
               {this.state.text}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {this.props.children}
            </Dropdown.Menu>
         </Dropdown>
      );
   }
}
