import React, { Component } from 'react';

import { Accordion, Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSync } from '@fortawesome/free-solid-svg-icons';
import QuantidadeDeAcessoAoSistemaController from '../../controllers/Tecnometrics/QuantidadeDeAcessoAoSistemaController';

export default class QuantidadeDeAcessoAoSistemaView extends Component {
   constructor(props) {
      super(props);
      this.state = { itens: [], activeKey: null, loaded: false, loading: false };
      this.controller = new QuantidadeDeAcessoAoSistemaController(this);
   }

   refreshData() {
      this.setState({ loading: true }, () => {
         this.loadData().then(() => {
            this.setState({ loading: false });
         });
      });
   }

   loadData() {
      this.setState({ loading: true });
      return this.controller.obterQuantidadeDeAcessoAoSistemaPorEmpresa();
   }

   toggleSection = (key) => {
      if (!this.state.loaded) {
         this.loadData().then(() => {
            this.setState((prevState) => ({
               activeKey: prevState.activeKey === key ? null : key,
               loaded: true,
               loading: false,
            }));
         });
      } else {
         this.setState((prevState) => ({
            activeKey: prevState.activeKey === key ? null : key,
            loading: false,
         }));
      }
   };

   render() {
      let lang = this.props.lang;
      return (
         <Row style={{ marginRight: '0px' }}>
            <Col className='m-3'>
               <Accordion activeKey={this.state.activeKey}>
                  <Card>
                     <Card.Header>
                        <Button
                           variant='outline-none'
                           className='d-flex justify-content-between w-100'
                           style={{ outline: 'none', boxShadow: 'none' }}
                           onClick={() => this.toggleSection('0')}
                        >
                           {lang.quantidadeDeAcessosAoSistema.quantidadeDeAcessosAoSistema}
                           <FontAwesomeIcon icon={this.state.activeKey === '0' ? faChevronUp : faChevronDown} />
                        </Button>
                     </Card.Header>
                     <Accordion.Collapse eventKey='0'>
                        <Card.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
                           <Row className='bg-dark text-white p-2 fw-bold'>
                              <Col xs={7}>{lang.quantidadeDeAcessosAoSistema.empresa}</Col>
                              <Col xs={5}>{lang.quantidadeDeAcessosAoSistema.quantidade}</Col>
                           </Row>

                           {this.state.loading
                              ? lang.quantidadeDeAcessosAoSistema.carregando
                              : this.state.itens.map((item, index) => (
                                   <Row key={index} className='border-bottom p-2'>
                                      <Col xs={7}>{item.nomeFantasia}</Col>
                                      <Col xs={5}>{item.quantidade}</Col>
                                   </Row>
                                ))}
                           <Row className='border-bottom p-2'>
                              <Col xs={12}>
                                 <Button
                                    variant='outline-none'
                                    className='w-100 p-2 '
                                    style={{ outline: 'none', boxShadow: 'none' }}
                                    onClick={() => this.refreshData()}
                                 >
                                    <FontAwesomeIcon icon={faSync} />
                                 </Button>
                              </Col>
                           </Row>
                        </Card.Body>
                     </Accordion.Collapse>
                  </Card>
               </Accordion>
            </Col>
         </Row>
      );
   }
}
