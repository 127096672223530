import { Row, Col, FormGroup, Popover, OverlayTrigger } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import EventoController from '../../controllers/Empresa/EventoController';
import {
   buildQueryString,
   dateTimeToString,
   formatDate,
   updateState,
   prepareSearchable,
   numberToString,
} from '../../utils/Functions';
import TextArea from '../../components/TextArea';
import RadioInput from '../../components/RadioInput';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LayoutParams } from '../../config/LayoutParams';
import { showConfirm, showError } from '../../components/Messages';
import Select from '../../components/Select';
import PredioView from './PredioView';
import LocalView from './LocalView';
import EquipamentoView from './EquipamentoView';
import DateInput from '../../components/DateInput';
import Button from '../../components/Button';

var LANG = {};

export default class EventoView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         situacao: 1,
         dataInicial: null,
         dataFinal: null,
      };
      this.controller = new EventoController(this);
      LANG = props.lang;
   }

   renderizarFiltros = () => {
      return (
         <Fragment>
            <Row>
               <Col sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                     <Label>{LANG.telaDeCadastroDeEvento.equipamento}</Label>
                     <Select
                        name={'equipamento'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           this.setState({ equipamento: i });
                        }}
                        formularioPadrao={(select) => {
                           return <EquipamentoView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor={'#ffffff'}
                        getFilterUrl={(text) =>
                           '/equipamento' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                        }
                     />
                  </FormGroup>
               </Col>

               <Col sm={12} md={3} lg={3} xl={3}>
                  <FormGroup>
                     <Label>{LANG.telaDeCadastroDeEvento.local}</Label>
                     <Select
                        name={'local'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           this.setState({ local: i });
                        }}
                        formularioPadrao={(select) => {
                           return <LocalView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor={'#ffffff'}
                        getFilterUrl={(text) =>
                           '/local' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                        }
                     />
                  </FormGroup>
               </Col>

               <Col sm={12} md={3} lg={3} xl={3}>
                  <FormGroup>
                     <Label>{LANG.telaDeCadastroDeEvento.predio}</Label>
                     <Select
                        name={'predio'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           this.setState({ predio: i });
                        }}
                        formularioPadrao={(select) => {
                           return <PredioView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor={'#ffffff'}
                        getFilterUrl={(text) =>
                           '/predio' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                        }
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col sm={12} md={3} lg={3} xl={3}>
                  <FormGroup>
                     <Label>{LANG.telaDeCadastroDeEvento.dataInicial}</Label>
                     <DateInput
                        defaultValue={this.state.dataInicial}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataInicial = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col sm={12} md={3} lg={3} xl={3}>
                  <FormGroup>
                     <Label>{LANG.telaDeCadastroDeEvento.dataFinal}</Label>
                     <DateInput
                        defaultValue={this.state.dataFinal}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataFinal = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 35 }}>
                     <div>
                        <RadioInput
                           name={'situacao'}
                           label={LANG.telaDeCadastroDeEvento.pendente}
                           defaultValue={this.state.situacao === 1}
                           onChange={() => {
                              this.setState({ situacao: 1 });
                           }}
                        />
                     </div>
                     <div style={{ paddingLeft: 3 }}>
                        <RadioInput
                           name={'situacao'}
                           label={LANG.telaDeCadastroDeEvento.respondido}
                           defaultValue={this.state.situacao === 2}
                           onChange={() => {
                              this.setState({ situacao: 2 });
                           }}
                        />
                     </div>
                  </div>
               </Col>

               <Col style={{ marginTop: 25, maxWidth: 200 }} sm={12} md={3} lg={3} xl={3}>
                  <Button
                     onClickAsync={() => {
                        return new Promise((resolve, reject) => {
                           if (this.state.situacao !== 1) {
                              if (!this.state.dataInicial) {
                                 showError(LANG.telaDeCadastroDeEvento.informeADataInicial);
                                 reject();
                                 return;
                              }

                              if (!this.state.dataFinal) {
                                 showError(LANG.telaDeCadastroDeEvento.informeADataFinal);
                                 reject();
                                 return;
                              }
                           }

                           this.form
                              .filtrar()
                              .then(() => {
                                 resolve();
                              })
                              .catch(reject);
                        });
                     }}
                     inProgressText={LANG.telaDeCadastroDeEvento.consultando}
                     text={LANG.telaDeCadastroDeEvento.consultar}
                  />
               </Col>
            </Row>
         </Fragment>
      );
   };

   getFiltro = () => {
      return {
         situacao: this.state.situacao,
         dataInicial: formatDate(this.state.dataInicial),
         dataFinal: formatDate(this.state.dataFinal),
         local: this.state.local ? this.state.local.id : null,
         predio: this.state.predio ? this.state.predio.id : null,
         equipamento: this.state.equipamento ? this.state.equipamento.id : null,
      };
   };

   renderizarFormulario = () => {
      let tela = LANG.telaDeCadastroDeEvento;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{LANG.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.situacao}</Label>
                     <TextInput
                        readOnly
                        defaultValue={
                           this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.descricao
                        }
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{tela.dataDaUltimaAlteracao}</Label>
                     <TextInput defaultValue={dateTimeToString(this.state.itemSelecionado.dataDeAlteracao)} readOnly />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{tela.usuarioDaUltimaAlteracao}</Label>
                     <TextInput defaultValue={this.state.itemSelecionado.usuario?.nomeDeUsuario} readOnly />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.evento}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.descricaoDoEvento} />
                  </FormGroup>
               </Col>

               {(this.state.itemSelecionado.tipoDoEvento.id === 1 ||
                  this.state.itemSelecionado.tipoDoEvento.id === 2 ||
                  this.state.itemSelecionado.tipoDoEvento.id === 5 ||
                  this.state.itemSelecionado.tipoDoEvento.id === 6) && (
                  <Fragment>
                     <Col sm={6} md={3} lg={3}>
                        <FormGroup>
                           <Label>{tela.minimaNoPeriodo}</Label>
                           <TextInput
                              defaultValue={numberToString(
                                 this.state.itemSelecionado.minimaNoPeriodo,
                                 1,
                                 1,
                                 this.props.lang
                              )}
                              readOnly
                           />
                        </FormGroup>
                     </Col>
                     <Col sm={6} md={3} lg={3}>
                        <FormGroup>
                           <Label>{tela.maximaNoPeriodo}</Label>
                           <TextInput
                              defaultValue={numberToString(
                                 this.state.itemSelecionado.maximaNoPeriodo,
                                 1,
                                 1,
                                 this.props.lang
                              )}
                              readOnly
                           />
                        </FormGroup>
                     </Col>
                  </Fragment>
               )}
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.dataDaPrimeiraOcorrencia}</Label>
                     <TextInput
                        defaultValue={dateTimeToString(this.state.itemSelecionado.dataDaIrregularidade)}
                        readOnly
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{tela.regularizado}</Label>
                     <TextInput defaultValue={this.state.itemSelecionado.regularizado ? LANG.sim : LANG.nao} readOnly />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{tela.dataDaRegularizacao}</Label>
                     <TextInput
                        defaultValue={dateTimeToString(this.state.itemSelecionado.dataDeRegularizacao)}
                        readOnly
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{tela.duracao}</Label>
                     <TextInput defaultValue={this.state.itemSelecionado.duracao} readOnly />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                           <div style={{ display: 'table-cell' }}>{tela.causaProvavel}</div>
                           <div style={{ display: 'table-cell', paddingLeft: 3 }}>
                              <OverlayTrigger
                                 style={{}}
                                 trigger='click'
                                 placement='right'
                                 rootClose={true}
                                 overlay={
                                    <Popover id='popover-selecionar-causa'>
                                       <Popover.Content
                                          style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
                                       >
                                          {this.itemDeCausaProvavel(
                                             1,
                                             tela.causasProvaveis.portaAbera,
                                             tela.causasProvaveis.portaAbera
                                          )}
                                          {this.itemDeCausaProvavel(
                                             2,
                                             tela.causasProvaveis.posicaoDoSensor,
                                             tela.causasProvaveis.posicaoDoSensor
                                          )}
                                          {this.itemDeCausaProvavel(
                                             3,
                                             tela.causasProvaveis.termostato,
                                             tela.causasProvaveis.termostato
                                          )}
                                          {this.itemDeCausaProvavel(
                                             4,
                                             tela.causasProvaveis.sensorGlicerina,
                                             tela.causasProvaveis.sensorGlicerina
                                          )}
                                          {this.itemDeCausaProvavel(
                                             5,
                                             tela.causasProvaveis.sensorDesligado,
                                             tela.causasProvaveis.sensorDesligado
                                          )}
                                          {this.itemDeCausaProvavel(
                                             6,
                                             tela.causasProvaveis.excessoDeGelo,
                                             tela.causasProvaveis.excessoDeGelo
                                          )}
                                          {this.itemDeCausaProvavel(
                                             7,
                                             tela.causasProvaveis.problemasNoEquipamentoMonitorado,
                                             tela.causasProvaveis.problemasNoEquipamentoMonitorado
                                          )}
                                          {this.itemDeCausaProvavel(
                                             8,
                                             tela.causasProvaveis.outros,
                                             tela.causasProvaveis.detalharOMotivo
                                          )}
                                       </Popover.Content>
                                    </Popover>
                                 }
                              >
                                 <div className='noselect'>
                                    <FontAwesomeIcon
                                       icon={faPlusCircle}
                                       style={{ fontSize: 18, color: LayoutParams.colors.corDoTextoPadrao }}
                                       cursor={'pointer'}
                                    />
                                 </div>
                              </OverlayTrigger>
                           </div>
                        </div>
                     </Label>
                     {!this.state.esconderCausaProvavel && (
                        <TextArea
                           defaultValue={this.state.itemSelecionado.causaProvavel}
                           onChange={(e) => {
                              updateState(this, (state) => (state.itemSelecionado.causaProvavel = e.target.value));
                           }}
                        />
                     )}
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                           <div style={{ display: 'table-cell' }}>{tela.acaoRealizadaParaRegularizacao}</div>
                           <div style={{ display: 'table-cell', paddingLeft: 3 }}>
                              <OverlayTrigger
                                 style={{}}
                                 trigger='click'
                                 placement='right'
                                 rootClose={true}
                                 overlay={
                                    <Popover id='popover-selecionar-causa'>
                                       <Popover.Content
                                          style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
                                       >
                                          {this.itemDeAcaoRealizada(
                                             1,
                                             tela.acoesRealizadas.fechar,
                                             tela.acoesRealizadas.fechar + '.'
                                          )}
                                          {this.itemDeAcaoRealizada(
                                             2,
                                             tela.acoesRealizadas.reposicionar,
                                             tela.acoesRealizadas.reposicionar + '.'
                                          )}
                                          {this.itemDeAcaoRealizada(
                                             3,
                                             tela.acoesRealizadas.ajustarManualmente,
                                             tela.acoesRealizadas.ajustarManualmenteDetalhes + '.'
                                          )}
                                          {this.itemDeAcaoRealizada(
                                             4,
                                             tela.acoesRealizadas.assegurarQueSejaMantidoImerso,
                                             tela.acoesRealizadas.assegurarQueSejaMantidoImersoDetalhe
                                          )}
                                          {this.itemDeAcaoRealizada(
                                             5,
                                             tela.acoesRealizadas.enviarEmail,
                                             tela.acoesRealizadas.enviarEmailDetalhes
                                          )}
                                          {this.itemDeAcaoRealizada(
                                             6,
                                             tela.acoesRealizadas.providenciarDegelo,
                                             tela.acoesRealizadas.providenciarDegelo
                                          )}
                                          {this.itemDeAcaoRealizada(
                                             7,
                                             tela.acoesRealizadas.registrarChamado,
                                             tela.acoesRealizadas.registrarChamadoDetalhes
                                          )}
                                          {this.itemDeAcaoRealizada(
                                             8,
                                             tela.acoesRealizadas.outros,
                                             tela.acoesRealizadas.detalharOMotivo
                                          )}
                                       </Popover.Content>
                                    </Popover>
                                 }
                              >
                                 <div className='noselect'>
                                    <FontAwesomeIcon
                                       icon={faPlusCircle}
                                       style={{ fontSize: 18, color: LayoutParams.colors.corDoTextoPadrao }}
                                       cursor={'pointer'}
                                    />
                                 </div>
                              </OverlayTrigger>
                           </div>
                        </div>{' '}
                     </Label>
                     {!this.state.esconderAcaoRealizadaParaRegularizacao && (
                        <TextArea
                           defaultValue={this.state.itemSelecionado.acaoRealizadaParaRegularizacao}
                           onChange={(e) => {
                              updateState(
                                 this,
                                 (state) => (state.itemSelecionado.acaoRealizadaParaRegularizacao = e.target.value)
                              );
                           }}
                        />
                     )}
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.acaoRealizadaParaPrevenirReincidencia}</Label>
                     <TextArea
                        defaultValue={this.state.itemSelecionado.acaoRealizadaParaPrevenirReincidencia}
                        onChange={(e) => {
                           updateState(
                              this,
                              (state) => (state.itemSelecionado.acaoRealizadaParaPrevenirReincidencia = e.target.value)
                           );
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.observacoesAdicionais}</Label>
                     <TextArea
                        defaultValue={this.state.itemSelecionado.observacao}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.observacao = e.target.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <br />
         </React.Fragment>
      );
   };

   itemDeCausaProvavel = (codigo, titulo, descricao) => {
      return (
         <div
            style={{
               display: 'inline-flex',
               cursor: 'pointer',
               padding: 3,
            }}
            onClick={() => {
               updateState(this, (state) => {
                  state.itemSelecionado.codigoDaCausaProvavel = codigo;
                  state.itemSelecionado.causaProvavel = descricao;
                  state.esconderCausaProvavel = true;
               }).then(() => {
                  updateState(this, (state) => {
                     state.esconderCausaProvavel = false;
                  });
               });

               document.body.click();
            }}
         >
            <span>{codigo + '- ' + titulo}</span>
         </div>
      );
   };

   itemDeAcaoRealizada = (codigo, titulo, descricao) => {
      return (
         <div
            style={{
               display: 'inline-flex',
               cursor: 'pointer',
               padding: 3,
            }}
            onClick={() => {
               updateState(this, (state) => {
                  state.itemSelecionado.codigoDaAcaoRealizadaParaRegularizacao = codigo;
                  state.itemSelecionado.acaoRealizadaParaRegularizacao = descricao;
                  state.esconderAcaoRealizadaParaRegularizacao = true;
               }).then(() => {
                  updateState(this, (state) => {
                     state.esconderAcaoRealizadaParaRegularizacao = false;
                  });
               });
               document.body.click();
            }}
         >
            <span>{codigo + '- ' + titulo}</span>
         </div>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={LANG.telaDeCadastroDeEvento.titulo}
            url={'/evento'}
            fastUrl={'/evento/fast'}
            api={this.props.api}
            lang={this.props.lang}
            modal={this.props.modal}
            ref={(c) => (this.form = c)}
            ordenacaoPadrao={'id desc'}
            permissoes={[1491, null, 1493, null]}
            getFiltro={this.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            renderizarFiltros={this.renderizarFiltros}
            esconderTitulo={this.props.esconderTitulo}
            tituloParaAlteracao={this.props.tituloParaAlteracao}
            getObjetoDeDados={() => {
               return new Promise((resolve, reject) => {
                  if (this.state.itemSelecionado.regularizado) {
                     showConfirm(
                        <div style={{ padding: '5px 3px 3px 0px' }}>
                           <RadioInput
                              name={'situacaoConfirmacao'}
                              label={LANG.telaDeCadastroDeEvento.pendente}
                              defaultValue={this.state.itemSelecionado.situacao === 1}
                              onChange={() => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.situacao = { id: 1 };
                                 });
                              }}
                           />
                           <RadioInput
                              name={'situacaoConfirmacao'}
                              label={LANG.telaDeCadastroDeEvento.respondido}
                              defaultValue={this.state.itemSelecionado.situacao === 2}
                              onChange={() => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.situacao = { id: 2 };
                                 });
                              }}
                           />
                        </div>,
                        () => {
                           this.controller.getObjetoDeDados().then(resolve).catch(reject);
                        },
                        () => {
                           reject();
                        }
                     );
                  } else {
                     this.controller.getObjetoDeDados().then(resolve).catch(reject);
                  }
               });
            }}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            aposSalvar={this.props.aposSalvar}
            aoCancelar={this.props.aoCancelar}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1 },
            }}
         />
      );
   }
}
