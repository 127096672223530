import { showError } from '../../components/Messages';
import { emptyStrIfNull } from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class TagController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'TagController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeTag.mac, width: '25%', orderby: 'mac' },
         { titulo: this.lang.telaDeCadastroDeTag.modelo, width: '25%', orderby: 'modelo' },
         { titulo: this.lang.telaDeCadastroDeTag.bateria, width: '20%', orderby: 'bateria' },
         { titulo: this.lang.telaDeCadastroDeTag.temperatura, width: '20%', orderby: 'temperatura' },
         { titulo: this.lang.telaDeCadastroDeTag.situacao, width: '20%', orderby: 'situacao' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.mac,
         item.modelo ? item.modelo.descricao : null,
         emptyStrIfNull(item.percentualDeBateria ? item.percentualDeBateria + '%' : null) +
            (item.bateria !==  item.percentualDeBateria ? ' (' + item.bateria + 'v)' : ''),
         item.temperatura ? parseInt(item.temperatura) + 'º' : null,
         item.situacao.descricao,
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.mac) {
            showError(this.lang.telaDeCadastroDeTag.mensagens.informeOMacDaTag);
            reject();
            return;
         }

         if (!item.modelo) {
            showError(this.lang.telaDeCadastroDeTag.mensagens.informeOModeloDaTag);
            reject();
            return;
         }

         var input = {
            mac: item.mac,
            situacao: item.situacao ? item.situacao.id : null,
            modelo: item.modelo ? item.modelo.id : null,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
