import React, { Component } from 'react'; 
import QuantidadeDeAcessoAoSistemaView from './QuantidadeDeAcessoAoSistemaView'

export default class HomeTecnometricsView extends Component {
	render() {
		return (
			<React.Fragment>
				<QuantidadeDeAcessoAoSistemaView lang={this.props.lang}></QuantidadeDeAcessoAoSistemaView>
			</React.Fragment>
		);
	}
}
